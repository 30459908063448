import styled from '@emotion/styled';
import { Base } from '@myungsoo/components';

const Button = styled(Base)`
  box-sizing: border-box;
  display: inline-block;
  padding: 0.5rem;

  font-size: inherit;
  font-weight: bold;
  text-decoration: none;

  appearance: none;
  color: inherit;
  background: none;
  background-color: rgba(127, 127, 127, 0.1);
  border: none;

  cursor: pointer;
  touch-action: manipulation;

  @media (hover: hover) {
    :hover:not([disabled]) {
      color: black;
      background-color: white;
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

Button.propTypes = {
  ...Base.propTypes,
};

Button.defaultProps = {
  ...Base.defaultProps,
  render: 'button',
};

export default Button;
