import React from 'react';
import { css } from '@emotion/core';
import { Label } from '@myungsoo/components';

const FormField = ({ label, children, ...restProps }) => (
  <div
    css={css`
      display: flex;
      flex-flow: column nowrap;

      > * {
        flex: 1;
      }
    `}
    {...restProps}
  >
    <Label
      css={css`
        padding: 0;
        padding-bottom: 0.25rem;
        cursor: inherit;
      `}
    >
      {label}
    </Label>
    {children}
  </div>
);

export default FormField;
