import React from 'react';
import _ from 'lodash';
import Measure from 'react-measure';
import styled from '@emotion/styled';

const Header = styled(({ onResize = () => {}, ...restProps }) => {
  const [bounds, setBounds] = React.useState({});

  React.useEffect(() => {
    onResize(bounds);
  }, [onResize, bounds]);

  return (
    <Measure
      bounds
      onResize={(contentRect) => {
        if (_.isEqual(contentRect.bounds, bounds)) {
          return;
        }

        setBounds(contentRect.bounds);
      }}
    >
      {({ measureRef }) => <header ref={measureRef} {...restProps} />}
    </Measure>
  );
})`
  height: 60px;

  display: flex;
  align-items: stretch;

  background-color: rgba(0, 0, 0, 0.8);
  transition: background-color 0.3s;
`;

export default Header;
