import 'normalize.css';

import { css } from '@emotion/core';

export default css`
  * {
    -webkit-overflow-scrolling: touch;
  }

  html {
    min-height: 100%;
    height: 100%;

    color: white;
    background-color: black;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif;
  }

  body {
    min-height: 100%;
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-top: 0;
  }

  hr {
    border-color: rgba(255, 255, 255, 0.1);
  }

  a {
    color: #00ccff;
  }

  #___gatsby {
    &,
    & > * {
      min-height: 100%;
      height: 100%;
    }
  }
`;
