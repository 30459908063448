import styled from '@emotion/styled';

const Group = styled.div`
  > * {
    display: inline-block;
    margin-right: 1px;
  }
`;

export default Group;
