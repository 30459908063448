import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import jsonp from 'jsonp';

const VideoThumbnail = styled(({ videoId, videoService, ...restProps }) => {
  const [src, setSrc] = React.useState();

  React.useEffect(() => {
    switch (videoService) {
      case 'youtube':
        setSrc(`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`);
        return;
      case 'vimeo':
        jsonp(`https://vimeo.com/api/v2/video/${videoId}.json`, null, (err, data) => {
          if (err) {
            console.error(err);
            setSrc();
            return;
          }
          setSrc(data[0].thumbnail_large);
        });
        return;
      default:
        setSrc();
        return;
    }
  }, [videoId, videoService]);

  return (
    <img
      css={[
        !src &&
          css`
            visibility: hidden;
          `,
      ]}
      src={src}
      alt=""
      {...restProps}
    />
  );
})`
  object-fit: cover;
`;

export default VideoThumbnail;
