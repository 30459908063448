import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { Button } from '@myungsoo/components';

const Title = styled(({ children, ...restProps }) => {
  const [hover, setHover] = React.useState(false);
  return (
    <Button
      render="a"
      css={[
        css`
          display: inline-flex;
          justify-content: center;
          align-items: center;

          background-color: transparent;

          font-size: 2.5rem;
          font-weight: 900;
          overflow: hidden;

          @media (hover: none) {
            :hover:not([disabled]) {
              color: inherit;
              background-color: transparent;
            }
          }
        `,
      ]}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      {...restProps}
    >
      <h1
        css={css`
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          font: inherit;
          overflow: hidden;
        `}
      >
        {children instanceof Function ? children({ hover }) : children}
      </h1>
    </Button>
  );
})``;

export default Title;
