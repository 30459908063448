import React from 'react';
import _ from 'lodash';
import { css } from '@emotion/core';
import firebase from 'firebase/app';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { Modal } from '@myungsoo/components';

const SortableItem = SortableElement(({ children }) => (
  <div
    css={css`
      cursor: move;
    `}
  >
    {children}
  </div>
));

const SortableList = SortableContainer(({ children }) => (
  <div>
    {React.Children.map(children, (child, index) => (
      <SortableItem index={index}>{child}</SortableItem>
    ))}
  </div>
));

const DocumentList = ({
  collectionPath = '',
  renderHeader: Header = ({ onAdd }) => null,
  renderDocument: Document = ({ doc, onClone, onEdit, onRemove }) => null,
  renderDocumentForm: DocumentForm = ({ doc, onSave }) => null,
  onLoaded = (docs) => {},
  onRemoved = (doc) => {},
  onLoadingChange = () => {},
  ...restProps
}) => {
  const [showDocForm, setShowDocForm] = React.useState(false);
  const [docs, setDocs] = React.useState([]);
  const [currentDoc, setCurrentDoc] = React.useState();

  const [db, collectionRef] = React.useMemo(() => {
    const db = firebase.firestore();
    const collectionRef = db.collection(collectionPath);

    return [db, collectionRef];
  }, [collectionPath]);

  const openDoc = React.useCallback((doc) => {
    setCurrentDoc(doc);
    setShowDocForm(true);
  }, []);

  const closeDoc = React.useCallback(() => {
    setShowDocForm(false);
    setCurrentDoc();
  }, []);

  const saveDocs = React.useCallback(async (newDocs) => {
    onLoadingChange(true);
    try {
      const batch = db.batch();

      _.forEach(newDocs, ({ id, ...doc }) => {
        if (id) {
          batch.update(collectionRef.doc(id), doc);
        } else {
          batch.set(collectionRef.doc(), {
            ...doc,
            displayOrder: -1,
            createdAt: new Date(),
          });
        }
      });

      await batch.commit();

      closeDoc();
    } finally {
      onLoadingChange(false);
    }
  }, [closeDoc, collectionRef, db, onLoadingChange]);

  const saveDoc = React.useCallback(async (doc) => {
    return saveDocs([doc]);
  }, [saveDocs]);

  const removeDoc = React.useCallback(async ({ id }) => {
    if (!window.confirm('Are you sure?')) {
      return;
    }

    onLoadingChange(true);
    try {
      await collectionRef.doc(id).delete();

      closeDoc();
    } finally {
      onLoadingChange(false);
    }
  }, [closeDoc, collectionRef, onLoadingChange]);

  React.useEffect(() => {
    const unsubscribe = collectionRef.orderBy('createdAt', 'desc').onSnapshot(
      (snapshot) => {
        const loadedDocs = [];
        snapshot.forEach((doc) => {
          loadedDocs.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setDocs(_.orderBy(loadedDocs, ['displayOrder']));

        snapshot.docChanges().forEach((change) => {
          if (change.type === 'removed') {
            const { doc } = change;
            onRemoved({
              id: doc.id,
              ...doc.data(),
            });
          }
        });
      },
      (error) => {
        console.error(error);
      },
    );

    return unsubscribe;
  }, [collectionRef, onLoaded, onRemoved]);

  React.useEffect(() => {
    onLoaded(docs);
  }, [onLoaded, docs]);

  const onSortEnd = React.useCallback(({ oldIndex, newIndex }) => {
    if (newIndex === oldIndex) {
      return;
    }
    const sortedDocs = _.map(
      arrayMove(docs, oldIndex, newIndex),
      (doc, index) => ({
        ...doc,
        displayOrder: index,
      }),
    );
    setDocs(sortedDocs);
    saveDocs(sortedDocs);
  }, [docs, saveDocs]);

  return (
    <div {...restProps}>
      <Header onAdd={() => openDoc()} />

      <SortableList
        lockAxis="y"
        useWindowAsScrollContainer
        lockToContainerEdges
        onSortEnd={onSortEnd}
      >
        {_.map(docs, (doc) => (
          <Document
            key={doc.id}
            doc={doc}
            onClone={() => saveDoc({ ...doc, id: undefined })}
            onEdit={() => openDoc(doc)}
            onRemove={() => removeDoc({ id: doc.id })}
          />
        ))}
      </SortableList>

      <Modal show={showDocForm} onClose={closeDoc}>
        <div
          css={css`
            flex: 1;
          `}
        >
          <DocumentForm doc={currentDoc} onSave={saveDoc} />
        </div>
      </Modal>
    </div>
  );
};

export default DocumentList;
