import styled from '@emotion/styled';

const Label = styled.label`
  display: inline-block;
  padding: 0.5rem;

  border: 1px solid transparent;

  font-weight: bold;

  cursor: pointer;
`;

export default Label;
