import React from 'react';
import { css } from '@emotion/core';
import { Input, Label } from '@myungsoo/components';

const Checkbox = ({
  css: externalCss,
  children,
  name,
  checked,
  inputProps,
  onChange,
  ...restProps
}) => (
  <Label
    css={[
      css`
        padding: 0;
        font-weight: normal;
      `,
      externalCss,
    ]}
    {...restProps}
  >
    <Input type="checkbox" name={name} checked={checked} onChange={onChange} {...inputProps} />
    {children}
  </Label>
);

export default Checkbox;
