import React from 'react';
import { css } from '@emotion/core';
import firebase from 'firebase/app';

import {
  Button,
  Container,
  Group,
  Loader,
  Modal,
  RichTextEditor,
} from '@myungsoo/components';

const AdminPageBase = ({
  id,
  config: { DB_ROOT_PATH },
  loading: loadingProp = false,
  showContent = true,
  children,
}) => {
  const [loading, setLoading] = React.useState(loadingProp);
  
  React.useEffect(() => {
    setLoading(loadingProp);
  }, [loadingProp]);
  
  const [content, setContent] = React.useState('');
  
  const pageRef = React.useMemo(() => {
    const db = firebase.firestore();
    return db.collection(`${DB_ROOT_PATH}/pages`).doc(id);
  }, [DB_ROOT_PATH, id])

  const loadPage = React.useCallback(async () => {
    setLoading(true);
    try {
      const pageDoc = await pageRef.get();

      if (!pageDoc.exists) {
        await pageRef.set({ content: '' });
        return loadPage();
      }

      const page = pageDoc.data();
      setContent(page.content);
    } finally {
      setLoading(false);
    }
  }, [pageRef]);

  const savePage = React.useCallback(async () => {
    setLoading(true);
    try {
      await pageRef.set({ content });
      await loadPage();
    } finally {
      setLoading(false);
    }
  }, [pageRef, loadPage, content]);

  React.useEffect(() => {
    loadPage();
  }, [loadPage]);

  return (
    <Container
      css={css`
        position: relative;
        flex: 1;
        height: 100%;
      `}
    >
      {showContent && (
        <div
          css={css`
            max-height: 100%;
            display: flex;
            flex-flow: column nowrap;
          `}
        >
          <RichTextEditor
            css={css`
              flex: 1;
              margin-bottom: 0.5rem;
            `}
            value={content}
            onChange={(value) => setContent(value)}
          />

          <Group
            css={css`
              text-align: right;
            `}
          >
            <Button onClick={savePage}>Save</Button>
          </Group>
        </div>
      )}

      {children}

      <Modal show={loading} showCloseButton={false}>
        <Loader />
      </Modal>
    </Container>
  );
};

export default AdminPageBase;
