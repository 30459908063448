/* eslint-disable react/default-props-match-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Base } from '@myungsoo/components';

const ImageText = ({ src, useMask, ...restProps }) => (
  <Base
    css={[
      css`
        display: inline-block;
        box-sizing: border-box;
        max-width: 100%;
        max-height: 100%;
        font-size: inherit;
        font-weight: inherit;
        white-space: nowrap;
        overflow: hidden;
      `,
      src &&
        !useMask &&
        css`
          color: transparent;
          background-image: url(${src});
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
        `,
      src &&
        useMask &&
        css`
          background-color: currentColor;
          mask-image: url(${src});
          mask-repeat: no-repeat;
          mask-size: contain;
          mask-position: center;
        `,
    ]}
    {...restProps}
  />
);

ImageText.propTypes = {
  ...Base.propTypes,
  src: PropTypes.string,
  useMask: PropTypes.bool,
};

ImageText.defaultProps = {
  ...Base.defaultProps,
  src: undefined,
  useMask: true,
  render: 'span',
};

export default ImageText;
