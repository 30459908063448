import React from 'react';
import ReactDOMServer from 'react-dom/server';
import styled from '@emotion/styled';

import { Base } from '@myungsoo/components';

const Svg = styled(Base)``;

Svg.propTypes = {
  ...Base.propTypes,
};

Svg.defaultProps = {
  ...Base.defaultProps,
  render: 'svg',
};

Svg.renderToDataUri = (element) => {
  const svgString = ReactDOMServer.renderToStaticMarkup(
    <element.type xmlns="http://www.w3.org/2000/svg" {...element.props} />,
  );
  return `data:image/svg+xml;utf8,${encodeURIComponent(svgString)}`;
};

export default Svg;
