import React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import closeIcon from '@fortawesome/fontawesome-free/svgs/solid/times.svg';

import { Button, ImageText } from '@myungsoo/components';

const Modal = styled(
  ({ show = false, showCloseButton = true, children, onClose, ...restProps }) =>
    show && (
      <>
        <Global
          styles={css`
            body {
              overflow: hidden;
            }
          `}
        />
        <div
          css={[
            css`
              position: fixed;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;

              display: flex;
              flex-flow: row nowrap;
              justify-content: center;
              align-items: center;

              background: rgba(0, 0, 0, 0.95);

              z-index: 10;
              overflow: auto;
            `,
          ]}
          {...restProps}
        >
          <div
            css={css`
              flex: 1;
              display: flex;
              justify-content: center;
              max-height: 100%;
            `}
          >
            {children}
          </div>
          {showCloseButton && (
            <Button
              css={css`
                position: absolute;
                top: 0;
                right: 0;
                padding: 0.5rem;
                color: white;
                background: none;
              `}
              onClick={onClose}
            >
              <ImageText
                css={css`
                  width: 48px;
                  height: 48px;
                `}
                src={closeIcon}
              >
                Close
              </ImageText>
            </Button>
          )}
        </div>
      </>
    ),
)``;

export default Modal;
