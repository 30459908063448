import React from 'react';
import { css } from '@emotion/core';

const Input = ({ type, ...restProps }) => (
  <input
    type={type}
    css={[
      css`
        padding: 0.5rem;

        color: inherit;
        background: none;
        border: 1px solid rgba(255, 255, 255, 0.2);

        font-size: inherit;
      `,
      type === 'checkbox' && css`
        cursor: pointer;
      `,
      css`
        &[disabled] {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &[readonly] {
          opacity: 0.5;
          cursor: initial;
        }
      `,
    ]}
    {...restProps}
  />
);

export default Input;
