import React from 'react';
import { Global, css } from '@emotion/core';
import { Link } from 'gatsby';
import fp from 'lodash/fp';

import { Base, Modal, Loader } from '@myungsoo/components';
import { Header, Menu } from '@myungsoo/base/components';
import { global as globalStyles } from '@myungsoo/base/styles';

const BaseLayout = ({
  children,
  loading = false,
  showMenu = true,
  showHeader = true,
  showFooter = true,
  renderTitle: Title = () => null,
  renderPostHeaderContent: PostHeaderContent = () => null,
  menuItems = [],
  renderFooter: Footer = () => null,
  onHeaderResize = () => {},
  ...restProps
}) => {
  const [headerBounds, setHeaderBounds] = React.useState({});

  React.useEffect(() => {
    onHeaderResize(headerBounds);
  }, [onHeaderResize, headerBounds]);

  return (
    <Base
      css={[
        css`
          box-sizing: border-box;
          min-height: 100%;
          display: flex;
          flex-flow: column nowrap;
          padding-top: ${showHeader ? headerBounds.height : 0}px;
        `,
      ]}
      {...restProps}
    >
      <Global styles={globalStyles} />

      {showHeader && (
        <Header
          css={[
            css`
              position: fixed;
              left: 0;
              top: 0;
              right: 0;
              z-index: 10;
            `,
          ]}
          onResize={setHeaderBounds}
        >
          <Title
            css={css`
              order: 1;
            `}
          />

          <div
            css={css`
              order: 2;
              flex: 1;
            `}
          />

          {showMenu && (
            <Menu
              css={css`
                order: 3;
              `}
              buttonSize={headerBounds.height}
            >
              {fp.map((menuItemProps) => (
                <Menu.Item render={Link} activeClassName="active" {...menuItemProps} />
              ))(menuItems)}
            </Menu>
          )}

          <PostHeaderContent
            css={css`
              order: 4;
            `}
          />
        </Header>
      )}

      <main
        css={css`
          flex: 1;
          display: flex;
          flex-flow: column nowrap;
        `}
      >
        {children}
      </main>

      {showFooter && <Footer />}

      <Modal show={loading} showCloseButton={false}>
        <Loader />
      </Modal>
    </Base>
  );
};

export default BaseLayout;
