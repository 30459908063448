import React from 'react';
import { css, keyframes } from '@emotion/core';

const ring = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Loader = () => (
  <div
    css={css`
      display: inline-block;
      position: relative;
      width: 64px;
      height: 64px;

      > div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 51px;
        height: 51px;
        margin: 6px;
        border: 6px solid #fff;
        border-radius: 50%;
        animation: ${ring} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;
      }

      > div:nth-of-type(1) {
        animation-delay: -0.45s;
      }
      > div:nth-of-type(2) {
        animation-delay: -0.3s;
      }
      > div:nth-of-type(3) {
        animation-delay: -0.15s;
      }
    `}
  >
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Loader;
