import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Base, Button } from '@myungsoo/components';

const ToggleButton = ({ opened, size, ...restProps }) => {
  const barThickness = 4;
  const barSize = size * 0.5;
  const barGap = barSize * 0.5 - barThickness;

  const barStyle = css`
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: ${size * 0.5 - barThickness * 0.5}px;
    left: ${(size - barSize) * 0.5}px;
    width: ${barSize}px;
    height: 0;
    border: ${barThickness * 0.5}px solid currentColor;
  `;

  return (
    <Button
      css={[
        css`
          opacity: 1;
          padding: 0;
          color: white;
          background-color: transparent;
          outline: none;
        `,
        css`
          @media (hover: none) {
            :hover:not([disabled]) {
              color: inherit;
              background-color: none;
            }
          }
        `,
        css`
          position: relative;
          width: ${size}px;
          height: ${size}px;
        `,
        css`
          ::before,
          ::after {
            ${barStyle};
            content: '';
            transition: transform 0.3s;
          }

          ::before {
            transform: translateY(-${barGap}px);
          }

          ::after {
            transform: translateY(${barGap}px);
          }
        `,
        opened &&
          css`
            ::before {
              transform: rotateZ(45deg) scaleX(1.414);
            }

            ::after {
              transform: rotateZ(-45deg) scaleX(1.414);
            }
          `,
      ]}
      {...restProps}
    >
      <Base
        render="span"
        css={[
          css`
            ${barStyle};
            font-size: 0;
            transition: opacity .3s;
          `,
          opened && css`
            opacity: 0;
          `,
        ]}
      >
        {opened ? 'Close Menu' : 'Open Menu'}
      </Base>
      &nbsp;
    </Button>
  );
};

ToggleButton.propTypes = {
  ...Button.propTypes,
  opened: PropTypes.bool,
  size: PropTypes.number,
};

ToggleButton.defaultProps = {
  ...Button.defaultProps,
  opened: false,
  size: 60,
};

export default ToggleButton;
