import styled from '@emotion/styled';

const Container = styled.div`
  box-sizing: border-box;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 1rem;

  word-break: keep-all;
  word-wrap: break-word;

  img {
    max-width: 100%;
  }
`;

export default Container;
