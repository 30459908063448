import React from 'react';
import { css } from '@emotion/core';

import { Button, Container, FormField, Group } from '@myungsoo/components';

const DocumentForm = ({
  doc: docProp,
  renderFields: FieldList,
  onSave,
  ...restProps
}) => {
  const [doc, setDoc] = React.useState(docProp);

  const updateDoc = React.useCallback((newPartialDoc) => {
    setDoc((prevDoc) => ({
      ...prevDoc,
      ...newPartialDoc,
    }));
  }, []);

  return (
    <Container
      css={[
        css`
          > * {
            margin-bottom: 1.5rem;
          }
        `,
      ]}
      {...restProps}
    >
      <FieldList doc={doc} onUpdate={updateDoc} />

      <FormField>
        <Group
          css={css`
            text-align: right;
          `}
        >
          <Button onClick={() => onSave(doc)}>Save</Button>
        </Group>
      </FormField>
    </Container>
  );
};

export default DocumentForm;
