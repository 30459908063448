import React from 'react';
import PropTypes from 'prop-types';
import { ClassNames, css } from '@emotion/core';

import { Button } from '@myungsoo/components';
import { mediaQueries as mq } from '@myungsoo/base/styles';

const Item = ({ active, className, ...restProps }) => (
  <ClassNames>
    {({ cx }) => (
      <Button
        css={[
          css`
            display: inline-flex;
            align-items: center;

            width: 100%;
            padding: 1rem;
            text-align: left;
            text-transform: uppercase;

            line-height: normal;
            font-size: 3.5rem;
            font-weight: 900;

            opacity: 1;
            color: white;
            background-color: transparent;

            :hover,
            &.active {
              color: black;
              background-color: white;
            }

            @media ${mq[0]} {
              height: 100%;
              justify-content: center;
              font-size: 1.7rem;
              padding: 0 1rem;
            }
          `,
        ]}
        className={cx({ active }, className)}
        {...restProps}
      />
    )}
  </ClassNames>
);

Item.propTypes = {
  ...Button.propTypes,
  active: PropTypes.bool,
};

Item.defaultProps = {
  ...Button.defaultProps,
  active: false,
};

export default Item;
