import React from 'react';
import './quill.snow.css';

const modules = {
  toolbar: [
    // [{ font: [] }, { size: ['10px', '18px', '32px', '48px', '64px'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    [
      // { header: [1, 2, 3, 4, 5, 6, false] },
      // 'blockquote',
      // 'code-block',
    ],

    [
      { list: 'ordered' },
      { list: 'bullet' },
      // { indent: '-1' },
      // { indent: '+1' },
    ],
    [
      // { direction: 'rtl' },
      { align: [] },
    ],
    ['link', 'image', 'video'],

    ['clean'],
  ],
};

const RichTextEditor = ({ ...restProps }) => {
  const [reactQuillModule, setReactQuillModule] = React.useState();

  React.useEffect(() => {
    import('react-quill').then((result) => {
      const {
        default: { Quill },
      } = result;

      const FontAttributor = Quill.import('attributors/style/font');
      FontAttributor.whitelist = undefined;
      Quill.register(FontAttributor, true);

      const SizeAttributor = Quill.import('attributors/style/size');
      SizeAttributor.whitelist = undefined;
      Quill.register(SizeAttributor, true);

      const AlignAttributor = Quill.import('attributors/style/align');
      AlignAttributor.whitelist = undefined;
      Quill.register(AlignAttributor, true);

      setReactQuillModule(result);
    });
  }, []);

  if (!reactQuillModule) {
    return <div />;
  }

  const ReactQuill = reactQuillModule.default;

  return (
    <ReactQuill
      modules={modules}
      theme="snow"
      {...restProps}
    />
  );
};

export default RichTextEditor;
