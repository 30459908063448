import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Base } from '@myungsoo/components';
import { mediaQueries as mq } from '@myungsoo/base/styles';

import Item from './Item';
import ToggleButton from './ToggleButton';

const Menu = ({
  buttonSize,
  children,
  ...restProps
}) => {
  const [opened, setOpened] = React.useState(false);

  return (
    <Base {...restProps}>
      <ToggleButton
        css={[
          css`
            @media ${mq[0]} {
              display: none;
            }
          `,
        ]}
        opened={opened}
        size={buttonSize}
        onClick={() => setOpened(!opened)}
      />
      <Base
        css={[
          css`
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;

            padding-top: ${buttonSize}px;

            background-color: rgba(0, 0, 0, 0.8);
            transition: all 0.3s;

            overflow: auto;
            visibility: hidden;
            opacity: 0;
            pointer-events: none;
            z-index: -1;
          `,
          opened &&
            css`
              visibility: visible;
              opacity: 1;
              pointer-events: auto;
            `,
          css`
            @media ${mq[0]} {
              position: static;
              height: 100%;
              padding: 0;
              visibility: visible;
              opacity: 1;
              background: none;
              pointer-events: auto;
            }
          `,
        ]}
      >
        <ul
          css={[
            css`
              margin: 0;
              padding: 0;
              list-style: none;
              min-height: 100%;

              display: flex;
              flex-flow: column nowrap;
              justify-content: center;
              align-items: stretch;

              @media ${mq[0]} {
                flex-flow: row nowrap;
              }
            `,
          ]}
        >
          {React.Children.map(children, (child) => (
            <li>{child}</li>
          ))}
        </ul>
      </Base>
    </Base>
  );
};

Menu.Item = Item;

Menu.propTypes = {
  ...Base.propTypes,
  buttonSize: PropTypes.number,
};

Menu.defaultProps = {
  ...Base.defaultProps,
  buttonSize: ToggleButton.defaultProps.size,
  render: 'nav',
};

export default Menu;
