import React from 'react';
import PropTypes from 'prop-types';

const Base = ({ render: Component, ...restProps }) => (
  <Component {...restProps} />
);

Base.propTypes = {
  render: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.any,
  ]),
};

Base.defaultProps = {
  render: 'div',
};

export default Base;
